import { IInforme } from "../informes/types";
import { IObra } from "../obras/types";
/**
 * types
 */
export enum EdificioTypes {
  /*GET EDIFICIOS*/
  GET_EDIFICIOS = "GET_EDIFICIOS",
  SET_EDIFICIOS = "SET_EDIFICIOS",
  EDIFICIOS_LOADING = "EDIFICIOS_LOADING",
  /*GET EDIFICIOS UNREAD*/
  GET_EDIFICIOS_UNREAD = "GET_EDIFICIOS_UNREAD",
  SET_EDIFICIOS_UNREAD = "SET_EDIFICIOS_UNREAD",
  /*GET EDIFICIOS OBRAS*/
  GET_EDIFICIO_OBRAS = "GET_EDIFICIO_OBRAS",
  SET_EDIFICIO_OBRAS = "SET_EDIFICIO_OBRAS",
  /*POST EDIFICIO*/
  POST_EDIFICIO_REQUEST = "POST_EDIFICIO_REQUEST",
  POST_EDIFICIO_SUCCESS = "POST_EDIFICIO_SUCCESS",
  POST_EDIFICIO_FAIL = "POST_EDIFICIO_FAIL",
  /*POST EDIFICIO OBRA*/
  POST_EDIFICIO_OBRAS_REQUEST = "POST_EDIFICIO_OBRAS_REQUEST",
  POST_EDIFICIO_OBRAS_SUCCESS = "POST_EDIFICIO_OBRAS_SUCCESS",
  /*GET EDIFICIO BY ID*/
  GET_EDIFICIO_BY_ID = "GET_EDIFICIO_BY_ID",
  SET_EDIFICIO_BY_ID = "SET_EDIFICIO_BY_ID",
  /*GET EDIFICIO COPROPIETARIO*/
  GET_EDIFICIO_COPROPIETARIO = "GET_EDIFICIO_COPROPIETARIO",
  SET_EDIFICIO_COPROPIETARIO = "SET_EDIFICIO_COPROPIETARIO",
  /*SET EDIFICIO ERROR MESSAGE*/
  SET_EDIFICIO_ERROR_MESSAGE = "SET_EDIFICIO_ERROR_MESSAGE",
  /*GET EDIFICIO OBRAS*/
  GET_EDIFICIO_SELECTED_OBRAS = "GET_EDIFICIO_SELECTED_OBRAS",
  SET_EDIFICIO_SELECTED_OBRAS = "SET_EDIFICIO_SELECTED_OBRAS",
    /*GET EDIFICIO INFORMES*/
    GET_EDIFICIO_INFORMES = "GET_EDIFICIO_INFORMES",
    SET_EDIFICIO_INFORMES = "SET_EDIFICIO_INFORMES",
  /*LOGOUT*/
  LOGOUT = "LOGOUT",
}

export type Params = { id: string | undefined };

/**
 * Data types
 */
export interface ICopropietario {
  _id?: any;
}
export interface IEdificio {
  _id?: any;
  nombre?: string;
  direccion?: { calle?: string; numeroPuerta?: string };
  padron?: string;
  ciudad?: string;
  barrio?: string;
  activo?: boolean;
  createdBy?: any;
  imgUrl?: string;
  createdAt?: Date;
  updatedAt?: Date;
  readBy?: [{ readerId?: string; readedAt?: Date }];
  obras?: [IObra];
  empresas?: [any];
  servicios?: [any];
  informes?: [IInforme];
  consultas?: [any];
  presupuestos?:[any];
  memorias?:[any];
  tareas?: [any];
  avisos?: [any];
  administracion?: any;
  tecnicos?: [any];
  copropietarios?: [
    { _id: any; unidades?: [{}]; copropietario: ICopropietario }
  ];
}

/**
 * State type
 */
export interface EdificioState {
  isLoad?: boolean;
  inPromise?: boolean;
  edificios?: [IEdificio];
  edificiosUnread?: [IEdificio];
  edificioNuevo?: IEdificio;
  edificioById?: IEdificio;
  edificioCopropietario?: IEdificio;
  message?: string;
  errorMessage?: string;
  informes?: [IInforme];
}

/**
 * Action type
 */
export interface getEdificiosAction {
  type: typeof EdificioTypes.GET_EDIFICIOS;
}
export interface setEdificiosAction {
  type: typeof EdificioTypes.SET_EDIFICIOS;
  payload: { edificios: [IEdificio] };
}
export interface getEdificioInfomesAction {
  type: typeof EdificioTypes.GET_EDIFICIO_INFORMES;
  payload: { id: string };
}
export interface setEdificioInformesAction {
  type: typeof EdificioTypes.SET_EDIFICIO_INFORMES;
  payload: { informes: [IInforme] };
}
export interface getEdificiosUnreadAction {
  type: typeof EdificioTypes.GET_EDIFICIOS_UNREAD;
}
export interface setEdificiosUnreadAction {
  type: typeof EdificioTypes.SET_EDIFICIOS_UNREAD;
  payload: { edificios: [IEdificio] };
}
export interface getEdificioByIdAction {
  type: typeof EdificioTypes.GET_EDIFICIO_BY_ID;
  payload: { id: string };
}
export interface setEdificioByIdAction {
  type: typeof EdificioTypes.SET_EDIFICIO_BY_ID;
  payload: { edificio: IEdificio };
}

export interface getEdificioCopropietarioAction {
  type: typeof EdificioTypes.GET_EDIFICIO_COPROPIETARIO
}
export interface setEdificioCopropietarioAction {
  type: typeof EdificioTypes.SET_EDIFICIO_COPROPIETARIO;
  payload: { edificio: IEdificio };
}
export interface postEdificioRequestAction {
  type: typeof EdificioTypes.POST_EDIFICIO_REQUEST;
  payload: { edificio: FormData };
}
export interface postEdificioSuccessAction {
  type: typeof EdificioTypes.POST_EDIFICIO_SUCCESS;
  payload: { edificio: IEdificio; message: string };
}
export interface postEdificioFailAction {
  type: typeof EdificioTypes.POST_EDIFICIO_FAIL;
  payload: { message: string };
}
export interface setEdificioErrorMessageAction {
  type: typeof EdificioTypes.SET_EDIFICIO_ERROR_MESSAGE;
  payload: { message: string };
}
