import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import NotAuthorized from "../pages/NotAuthorized/NotAuthorized"; // Importa el componente de mensaje

const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const { isLogged } = useSelector((state: RootState) => state.users);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLogged === null ? (
          <NotAuthorized /> // Muestra una página vacía durante la carga
        ) : // Si está autenticado, renderiza el componente
        isLogged ? (
          <Component {...props} />
        ) : ( // Si no está autenticado, redirige al login
          <Redirect to="/" />
        )
      }
    />
  );
};

export default PrivateRoute;





