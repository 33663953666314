import React, { useState, useEffect } from "react";
import { IonContent, IonPage, IonButton, IonSpinner } from "@ionic/react";
import { useHistory } from "react-router-dom";
import "./NotAuthorized.css";
import "../../styles/App.css";

const NotAuthorized: React.FC = () => {
    const [showButton, setShowButton] = useState(false);
    const history = useHistory();

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowButton(true);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    const handleLoginRedirect = () => {
        history.push("/");
    };

    return (
        <IonPage>
            <IonContent fullscreen className="ion-content-grey">
                <div className="auth-centered-container">
                    {showButton && (
                        <>
                            <h2 className="auth-message font-14">Debes estar autenticado para acceder a esta página</h2>
                            <IonButton onClick={handleLoginRedirect} shape="round"
                                size="default"
                                expand="full" className="auth-login-button">
                                Ir al Login
                            </IonButton>
                        </>
                    )}
                </div>
            </IonContent>
        </IonPage>
    );
};

export default NotAuthorized;

