import React from 'react';
import { IonPage, IonContent, IonLoading } from '@ionic/react';
import './CenteredLoader.css'; // Archivo CSS para centrar el loader

const CenteredLoader: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="centered-loader">
          <IonLoading isOpen={true} message="Cargando..." spinner="circles" />
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CenteredLoader;