import {
  IonContent,
  IonPage,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  useIonRouter,
  IonAlert,
  IonIcon,
} from "@ionic/react";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./Login.css";
import "../../styles/App.css";
import { RootState } from "../../store/ducks/rootReducer";
import {
  logInRequest,
  getUserProfileRequest,
  clearErrorMessage,
} from "../../store/ducks/users/actions";
import { useForm } from 'react-hook-form';
import { ErrorMessage } from "@hookform/error-message";
import { alertCircleOutline } from "ionicons/icons";

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { _id, userProfile, isLogged, errorMessage, tipo } = useSelector((state: RootState) => state.users);
  const navigation = useIonRouter();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {

    if (_id !== "" && !userProfile.load) {
      dispatch(getUserProfileRequest());
    }

    if (isLogged) {
      console.log(tipo);
      // Redirige al usuario si está autenticado
      setError("");
      dispatch(clearErrorMessage());
      if(tipo !=="" && tipo == "copropietario"){
        console.log("holllaaa");
        navigation.push('/copropietario', 'root');
      }
    }


  }, [_id, isLogged, tipo, userProfile.load, navigation]);

  useEffect(() => {
    if (errorMessage !== "") {
      setError(errorMessage);
    }
  }, [errorMessage]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValues: {
      username: '',
      password: '',
    },
  });

  const onSubmit = (data: any) => {
    const username: string = data.username;
    const password: string = data.password;
    dispatch(logInRequest({ username, password }));
  };



  const clearError = () => {
    setError("");
    dispatch(clearErrorMessage());
  };

  return (
    <IonPage>
      {error !== "" && (
        <IonAlert
          isOpen={error !== ""}
          header="Aviso"
          message={errorMessage}
          buttons={[{ text: "OK", handler: clearError }]}
        />
      )}
      <IonContent fullscreen className="ion-login-content">
        <IonGrid className="ion-grid-login">
          <IonRow className="ion-justify-content-center ion-row-login">
            <IonCol
              className="ion-align-self-center col-login-form"
              size-md="6"
              size-lg="5"
              size-xs="12"
            >
              <div className="ion-padding align-center-logo-login">
                <div className="ion-logo-login"></div>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="ion-padding-login">
                  <IonItem className="ion-login-item">

                    <IonInput
                      className="ion-login-input"
                      {...register('username', {
                        required: 'Ingresa tu nombre de usuario',
                      })}
                      label="Nombre de usuario"
                      labelPlacement="floating"
                      onIonInput={() => clearErrors('username')}
                    ></IonInput>
                    
                  </IonItem>

                  <ErrorMessage
                    errors={errors}
                    name="username"
                    render={({ message }) => (
                      <div className="error-form">
                        <IonIcon icon={alertCircleOutline} slot="start" />
                        <span>{message}</span>
                      </div>
                    )}
                  />

                  <IonItem className="ion-login-item">

                    <IonInput
                      type="password"
                      className="ion-login-input"
                      {...register('password', {
                        required: 'Ingresa tu contraseña',
                      })}
                      label="Contraseña"
                      labelPlacement="floating"
                      onIonInput={() => clearErrors('password')}
                    ></IonInput>
                  </IonItem>

                  <ErrorMessage
                    errors={errors}
                    name="password"
                    render={({ message }) => (
                      <div className="error-form">
                        <IonIcon icon={alertCircleOutline} slot="start" />
                        <span>{message}</span>
                      </div>
                    )}
                  />
                </div>

                <div className="ion-padding">
                  <IonButton
                    shape="round"
                    size="default"
                    expand="full"
                    type="submit"
                    className="ion-button-login"
                  >
                    Ingresar
                  </IonButton>
                </div>
              </form>

            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default Login;
