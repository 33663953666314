import { jwtDecode } from "jwt-decode";

const getToken = () => {
  const token = localStorage.getItem("token");
  if (token !== null) return token
  else return "";
};

const isTokenExpired = (): boolean => {
  try {
    const tokenExpiration = localStorage.getItem("tokenExpiration");
    console.log(tokenExpiration);

    if (!tokenExpiration) {
      // Si no hay tokenExpiration, considera el token como expirado
      console.log("No tokenExpiration found");
      return true;
    }

    const numTokenExpiration = parseInt(tokenExpiration, 10);

    // Verifica si el token ha expirado
    if (Date.now() >= numTokenExpiration) {

      return true;
    }

    // Si el token no ha expirado
    return false;

  } catch (e) {
    console.log("Error in isTokenExpired:", e);
    // En caso de error, considera el token como expirado
    return true;
  }
};

const isAuthenticated = () => {
  try {
    const userId = localStorage.getItem("userId");

    // Primero, verifica si el userId está en localStorage.
    if (userId !== null) {
      // Si el userId existe, entonces chequea si el token ha expirado.
      const checkToken = isTokenExpired();

      // Si el token no ha expirado, devuelve true (autenticado).
      if (checkToken) {
        return true;
      }
      
      // Si el token ha expirado, devuelve false (no autenticado).
      return false;
    } else {
      // Si no hay userId, el usuario no está logueado.
      return false;
    }
  } catch (e) {
    console.log("Error en la autenticación", e);
    return false;
  }
};


const getUserId = () => {
  const id = localStorage.getItem("userId");
  if (id !== null) return id
  else return "";
};
const getPlatformStorage = () => {
  const platform = localStorage.getItem("platform");
  if (platform !== null) return platform
  else return "";
};
const getEdificioId = () => localStorage.getItem("edificioId");


const isEdificioSelected = () => (getEdificioId() ? true : false);

export { getToken, isTokenExpired, getUserId, isEdificioSelected, getPlatformStorage, isAuthenticated };
