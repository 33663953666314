import { combineReducers } from "redux";
import {userReducer} from "./users";
import {edificioReducer} from "./edificios";
import {obraReducer} from "./obras"
import { informeReducer } from "./informes";

const rootReducer =  combineReducers({
  users: userReducer,
  edificios: edificioReducer,
  obras: obraReducer,
  informes: informeReducer
});

export type RootState = ReturnType<typeof rootReducer>; 

export default rootReducer;