import {
  call,
  put,
  takeLatest,
} from "redux-saga/effects";
import { requestGetUserById, requestGetUserEdificios, requestGetUserProfile } from "../../../api/users";
import { signIn, logoutApi, checkAuth} from "../../../api/usersAuth";
import {
  logInSuccess,
  getUserSuccess,
  loginFail,
  logout,
  getUserProfileSuccess,
  setUserEdificios,
  checkAuthStatus
} from "./actions";
import { UsersTypes, LoginRequestAction, GetUserRequestAction, IUserSuccess, IUserProfile, checkAuthRequestAction } from "./types";
import { getToken, getUserId } from "../../../utils/auth";
import { IEdificio } from "../edificios/types";
import { useIonRouter } from "@ionic/react";

function* loginInRequest(action: LoginRequestAction) {
  try {
    const { data } = yield call(signIn, action.payload.user);
    const userResult: IUserSuccess = { _id: data._id, tipo: data.tipo };
    const message: string = data.message;
 console.log("login request");
    localStorage.setItem("userId", userResult._id);
   
    yield put(logInSuccess(userResult, message));
  } catch (error: any) {
    if (error.response) {
      let msg: string = "";
      const { status } = error.response;
      const { data } = error.response;
      if (status === 500) {
        msg = "Error en la conexión con el servidor."
      }
      if (data.message) {
        msg = data.message;
      }
      yield put(loginFail(msg));
    }

  }
}

function* loginOut() {
  try {
    localStorage.removeItem("userId");
    yield call(logoutApi);
  
  } catch (error) {
    console.log(error);
  }
}

function* checkAuthRequest() {
  try {
  

    // Realizamos la llamada para verificar la autenticación
    const { data } = yield call(checkAuth);
    console.log("sagas check log");
    console.log(data);

    // Si la autenticación es exitosa, creamos el objeto `user` y disparamos la acción de éxito
    const user: IUserSuccess = { _id: data._id, tipo: data.tipo };
    if(getUserId()=="")localStorage.setItem("userId", data._id);
    yield put(logInSuccess(user, "Sesión activa."));
  } catch (error) {
    console.error("Error al verificar la autenticación:", error);

    // Si ocurre un error, disparamos la acción de logout
    yield put(logout());
  }
}

function* getUserById({ payload }: GetUserRequestAction) {
  try {
    const _id = payload.id;
    const { data } = yield call(requestGetUserById, _id);
    console.log(data);
    const userProfile: IUserProfile = data.user;
    yield put(getUserSuccess(userProfile));
  } catch (error) {
    console.log(error);
  }
}

function* getUserProfile() {
  try {
    const { data } = yield call(requestGetUserProfile);
    console.log(data);
    const userProfile: IUserProfile = data.user;
    yield put(getUserProfileSuccess(userProfile));
  } catch (error) {
    console.log(error);
  }
}

function* getUserEdificiosRequest() {
  try {
    const { data } = yield call(requestGetUserEdificios);
    if (data.edificios) {
      const edificiosResult: [IEdificio] = data.edificios;
      yield put(setUserEdificios(edificiosResult));
    }
  } catch (error: any) {
    if (error.response) {
      console.log(error)
    }
  }
}

export function* userWatcherLoginRequest() {
  yield takeLatest(UsersTypes.LOGIN_REQUEST, loginInRequest);
}

export function* userWatcherLogout() {
  yield takeLatest(UsersTypes.LOGOUT, loginOut);
}

export function* userWatcherCheckAuthStatus() {
  yield takeLatest(UsersTypes.CHECK_AUTH_STATUS, checkAuthRequest);
}

export function* userWatcherGetUserById() {
  yield takeLatest(UsersTypes.GET_USER_REQUEST, getUserById);
}

export function* userWatcherGetUserProfile() {
  yield takeLatest(UsersTypes.GET_USER_PROFILE_REQUEST, getUserProfile);
}

export function* userWatcherGetUserEdificios() {
  yield takeLatest(UsersTypes.GET_USER_EDIFICIOS, getUserEdificiosRequest);
}

