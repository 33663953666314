import {
    call,
    put,
    takeLatest,
} from "redux-saga/effects";
import { requestGetObraById, requestGetObrasByEdificio } from "../../../api/obras";
import { setObraById, setObrasByEdificio } from "../obras/actions";
import { ObraTypes, getObrasAction, IObra, getObraByIdAction } from "../obras/types";


function* getObrasByEdificioRequest({ payload }: getObrasAction) {
    try {
        const idEdificio = payload.id;
        const { data } = yield call(requestGetObrasByEdificio, idEdificio);
        console.log("data");
        console.log(data.obras);
        const obrasResult: IObra[] = data.obras;
        console.log(obrasResult);
        yield put(setObrasByEdificio(obrasResult));
    } catch (error: any) {
        console.log(error)
        if (error.response) {
            console.log(error)
        }
    }
}

function* getObraByIdRequest({ payload }: getObraByIdAction) {
    try {
      const _id = payload.id;
      console.log(_id);
      const { data } = yield call(requestGetObraById, _id);
      const obraResult: IObra = data;
      console.log("obraResult");
      console.log(data);
      yield put(setObraById(obraResult));
    } catch (error) {
      console.log(error);
    }
  }

export function* obrasWatcherGetObrasByEdificio() {
    yield takeLatest(ObraTypes.GET_OBRAS_BY_EDIFICIO, getObrasByEdificioRequest);

}
export function* obrasWatcherGetObraById() {
    yield takeLatest(ObraTypes.GET_OBRA_BY_ID, getObraByIdRequest);

}