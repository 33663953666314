import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../store/ducks/rootReducer";
import { useEffect, useState } from "react";
import { getUserId } from "../utils/auth";

const LoginRoute = ({ component: Component, userHomePath, ...rest }: any) => {

  return (
    <Route
      {...rest}
      render={(props) =>
        getUserId()=="" ? <Component {...props} /> : <Redirect to={userHomePath} />
      }
    />
  );
};

export default LoginRoute;
