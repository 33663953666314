import { UsersTypes, UserState } from "./types";
import { Reducer } from "redux";

const initialState: UserState = {
  inPromise: false,
  tipo: "",
  userProfile: {
    load: false,
    tipo: ""
  },
  edificios: [],
  edificiosLoad: false,
  message: "",
  errorMessage: "",
  _id: "",
  isLogged: null,
  checkingAuth: false,
  checkLogged: false
};

export const userReducer: Reducer<UserState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UsersTypes.LOGIN_REQUEST:
      return { ...state, inPromise: true, message: "", errorMessage: "" };
    case UsersTypes.LOGIN_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        tipo: action.payload.user.tipo,
        isLogged: true,
        checkLogged: true,
        checkingAuth: false,
        _id: action.payload.user._id,
        ...action.payload.user,
        inPromise: false,
        errorMessage: "",
        userProfile: { ...state.userProfile },
      };
    case UsersTypes.CHECK_AUTH_STATUS:
      return {
        ...state,
        inPromise: true,
        checkingAuth: true
      };
    case UsersTypes.CHECK_AUTH_LOADING:
      return {
        ...state,
        checkingAuth: true,
      };
    case UsersTypes.LOGIN_FAIL:
      return {
        ...state,
        errorMessage: action.payload.message,
        inPromise: false,
      };

    case UsersTypes.GET_USER_REQUEST:
      return { ...state, inPromise: true };
    case UsersTypes.GET_USER_SUCCESS:
      return {
        ...state,
        userProfile: { load: true, ...action.payload.userProfile },
        inPromise: false,
      };
    case UsersTypes.GET_USER_PROFILE_REQUEST:
      return { ...state, inPromise: true };
    case UsersTypes.GET_USER_PROFILE_SUCCESS:
      return {
        ...state,
        userProfile: { load: true, ...action.payload.userProfile },
        inPromise: false,
      };
    case UsersTypes.GET_USER_EDIFICIOS:
      return {
        ...state,
        inPromise: true,
        edificiosLoad: false,
        message: "",
        errorMessage: ""
      };
    case UsersTypes.SET_USER_EDIFICIOS:
      return {
        ...state,
        edificios: action.payload.edificios,
        inPromise: false,
        isLoad: true
      };
    case UsersTypes.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      };
    case UsersTypes.LOGOUT:
      return { ...initialState, 
        isLogged: false  };
    default:
      return state;
  }
};
